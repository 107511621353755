.home {
    @include flexCenter(column);

    &__greeting {
        font-size: 2em;
        margin: 0.7em;
    }

    &__text {
        margin: 1em;
        font-size: 1.2em;
        line-height: 1.4em;
        text-align: center;
    }
}
