.projects {
    @include flexCenter(column);
    margin: 1em 0;
    &__project-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        width: 70vw;
        margin: 1em 0 1em;
        padding: 1em 2em;
    }
    &__thumbnail {
        width: 25vw;
    }
    &__info {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 20vw;
        line-height: 1.2em;
    }
    &__title {
        font-size: 1.3em;
        font-weight: bold;
        text-decoration: underline;
        margin: 2em 0 0.5em;
    }
    &__description {
        margin: 2em 0;
        font-style: italic;
    }
    &__data {
        margin: 0.3em 0;
    }
    &__data-category {
        font-weight: bolder;
    }
    &__link-container {
        @include flexCenter(row);
    }
    &__link {
        color: $accentColor;
        font-size: 2.5em;
    }

    // Media Query
    @include bp(ursa-minor) {
        &__project-container {
            flex-direction: column;
        }
        &__info {
            width: 50vw;
        }
        &__title {
            margin: 0.8em 0 0.5em;
        }
        &__link {
            font-size: 1.5em;
        }
        &__thumbnail {
            width: 60vw;
        }
    }
}
