.footer {
    @include flexCenter(column);
    width: 80vw;
    margin: 2em;
    &__contact-me {
        font-size: 1.5em;
        text-decoration: underline;
    }

    &__icon-container {
        @include flexCenter(row);
        margin: 1em 0 0.2em;
        justify-content: space-evenly;
        font-size: 2.7em;
        width: 50vw;
    }
    &__icon {
        color: $accentColor;
    }
}
