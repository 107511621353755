.app {
    @include flexCenter(column);

    background-color: $secondaryColor;

    &__content {
        @include flexCenter(column);

        border: 3px;
        border-color: $primaryColor;
        border-style: double;
        width: 80vw;
        padding: 1vw;
    }
}
